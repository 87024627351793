// extracted by mini-css-extract-plugin
export var customEnterpriseDevBenefitsSection = "K_hn";
export var customEnterpriseDevCallToActionSection = "K_hj";
export var customEnterpriseDevCaseSection = "K_hv";
export var customEnterpriseDevCostSection = "K_hp";
export var customEnterpriseDevExperienceSection = "K_ht";
export var customEnterpriseDevExpertiseSection = "K_hs";
export var customEnterpriseDevIndustriesSection = "K_hh";
export var customEnterpriseDevPracticesSection = "K_hq";
export var customEnterpriseDevPrimeSection = "K_hf";
export var customEnterpriseDevProcessSection = "K_hl";
export var customEnterpriseDevProjLogoSection = "K_hr";
export var customEnterpriseDevQuotesSection = "K_hw";
export var customEnterpriseDevServicesSection = "K_hg";
export var customEnterpriseDevSolutionSection = "K_hk";
export var customEnterpriseDevTechSection = "K_hm";